import { createContext, useContext, useState } from 'react';

const orderContext = createContext()

export function useOrderContext() {
    return useContext(orderContext)
}

export const OrderProvider = ({ children }) => {
    const [orderDetailsValue, setOrderDetailsValue] = useState();
    const [orderDescriptionValue, setOrderDescriptionValue] = useState();

    return (
        <orderContext.Provider value={{ orderDetailsValue, orderDescriptionValue, setOrderDetailsValue, setOrderDescriptionValue }}>
            {children}
        </orderContext.Provider>
    )
}

import * as HttpService from "./http.service";
import {
    GET_ALL_FEEDS,
    CREATE_FEED_URL,
    COMMENT_ON_FEEDS,
    GET_COMMENT_OF_FEED_URL,
    CREATE_SUB_COMMENT_ON_FEED,
    BOOKMARK_ON_FEEDS,
    GET_ALL_BOOKMARKS_FEEDS,
    GET_ALL_SAVED_MEDICINES,
    GET_FEED_BY_ID,
    GET_ALL_COMMUNITY_FEEDS,
    DELETE_MYADDRESS,
    USER_FOLLOW_UNFOLLOW,
    CREATE_LIKE_ON_COMMENT_URL,
    CREATE_LIKE_ON_SUBCOMMENT_URL,
    DELETE_COMMENT_ON_FEED_URL,
    DELETE_SUBCOMMENT_ON_FEED_URL,
    UPDATE_FEED_URL,
    DELETE_FEED_URL,
    USER_SEARCH_FOR_MENTION,
    GET_ALL_FEED_LIKES,
    USER_FOLLOW,
    USER_UNFOLLOW,
    GET_ALL_FEEDS_BY_USERID,
    GET_ALL_PINNED_FEED,
    GET_CHECK_NEW_FEED,
    GET_TRENDING_CATEGORIES,
    GET_ALL_FEEDS_V1,
    GET_SIMILAR_USERS,
    GET_TRENDING_ENGAGEMENT_ACTIVITIES
} from "./url.service";


export const getAllFeeds = (skip, limit, search, user_id, tagNames = "", sortingPostsByTrending = false, fetchingFeaturedPosts = false) => {
    return HttpService.getWithAuth(GET_ALL_FEEDS_V1(skip, limit, search, user_id, tagNames, sortingPostsByTrending, fetchingFeaturedPosts));
};
export const getAllBookmarksFeeds = (skip, limit, search, user_id) => {
    return HttpService.getWithAuth(GET_ALL_BOOKMARKS_FEEDS(skip, limit, search, user_id));
};
export const getAllCommunityFeeds = (skip, limit, search, user_id, community_id, post_type) => {
    return HttpService.getWithAuth(GET_ALL_COMMUNITY_FEEDS(skip, limit, search, user_id, community_id, post_type));
};
export const getAllSavedMedicines = (userID, pageNo, pageSize, search) => {
    return HttpService.getWithAuth(GET_ALL_SAVED_MEDICINES(userID, pageNo, pageSize, search));
};

export const getFeedById = (feed_id, user_id) => {
    return HttpService.getWithOutAuth(GET_FEED_BY_ID(feed_id, user_id));
};

export const deleteMyAddress = (address_id, user_id) => {
    return HttpService.deleteWithAuth(DELETE_MYADDRESS(address_id, user_id));
};

export const createFeed = (data) => {
    return HttpService.postWithAuth(CREATE_FEED_URL(), data);
};

export const getTrendingCategories = (data) => {
    return HttpService.getWithAuth(GET_TRENDING_CATEGORIES());
};

export const createLikeOnComment = (comment_id, user_id, feed_id) => {
    return HttpService.getWithAuth(CREATE_LIKE_ON_COMMENT_URL(comment_id, user_id, feed_id));
};

export const createLikeOnSubComment = (subcomment_id, user_id, feed_id) => {
    return HttpService.getWithAuth(CREATE_LIKE_ON_SUBCOMMENT_URL(subcomment_id, user_id, feed_id));
};

export const deleteCommentOnFeed = (feed_id, comment_id, user_id) => {
    return HttpService.getWithAuth(DELETE_COMMENT_ON_FEED_URL(feed_id, comment_id, user_id));
};

export const deleteSubCommentOnFeed = (feed_id, comment_id, subcomment_id, user_id) => {
    return HttpService.getWithAuth(DELETE_SUBCOMMENT_ON_FEED_URL(feed_id, comment_id, subcomment_id, user_id));
};

export const updateFeed = (feed_id, data) => {
    return HttpService.postWithAuth(UPDATE_FEED_URL(feed_id), data);
};


export const deleteFeed = (feed_id) => {
    return HttpService.getWithAuth(DELETE_FEED_URL(feed_id));
};

export const commentOnFeed = (params, data) => {
    const { feedId, userId, postOwnerId } = params
    return HttpService.postWithAuth(COMMENT_ON_FEEDS(feedId, userId, postOwnerId), data);
};

export const bookmarkOnFeed = (params) => {
    const { feedId, userId, postOwnerId } = params
    return HttpService.postWithAuth(BOOKMARK_ON_FEEDS(feedId, userId, postOwnerId));
};

export const getAllFeedsComment = (feedId, user_id) => {
    return HttpService.getWithAuth(GET_COMMENT_OF_FEED_URL(feedId, user_id));
};

export const createSubCommentsOnFeed = (feed_id, comment_id, postOwnerId, details) => {
    return HttpService.postWithAuth(CREATE_SUB_COMMENT_ON_FEED(feed_id, comment_id, postOwnerId), details);
};

export const UserFollowUnFollow = (user_id, followed_id, type) => {
    return HttpService.getWithAuth(USER_FOLLOW_UNFOLLOW(user_id, followed_id, type));
};
export const SearchUserForMention = (userName) => {
    return HttpService.getWithAuth(USER_SEARCH_FOR_MENTION(userName));
}

export const getAllFeedLikes = (feed_id, skip = 0, limit = 11, token) => {
    return HttpService.getWithToken(GET_ALL_FEED_LIKES(skip, limit, feed_id), token);
};

export const UserFollow = (data) => {
    return HttpService.postWithAuth(USER_FOLLOW(), data)
}

export const UserUnfollow = (data) => {
    return HttpService.putWithAuth(USER_UNFOLLOW(), data)
}

export const getAllFeedsByUserId = (skip, limit, search, userId, viewedBy) => {
    return HttpService.getWithAuth(GET_ALL_FEEDS_BY_USERID(skip, limit, search, userId, viewedBy))
}

export const getAllPinnedFeed = (userId) => {
    return HttpService.getWithAuth(GET_ALL_PINNED_FEED(userId))
}

export const getAllLatestFeed = (feedTime) => {
    return HttpService.getWithAuth(GET_CHECK_NEW_FEED(feedTime))
}

export const getSimilarUsers = (userId, limit) => {
    return HttpService.getWithAuth(GET_SIMILAR_USERS({ userId, limit }));
}

export const getTrendingEngagementActivities = (userId, limit, page) => {
    return HttpService.getWithAuth(GET_TRENDING_ENGAGEMENT_ACTIVITIES({ userId, limit, page }));
}
import * as HttpClient from './http.service';
import { GET_RESELLER_DASHBOARD_INFO, GET_RESELLER_PATIENT_CART, RESELLER_ADD_PATIENT_ADDRESS, RESELLER_ADD_PRESCRIPTION, RESELLER_ADD_TO_CART, RESELLER_BANK_DEATILS_KYC as RESELLER_BANK_DETAILS_KYC, RESELLER_CREATE_APPOINTMENT, RESELLER_CREATE_ORDER, RESELLER_CREATE_SHAREABLE_LINK, RESELLER_DELETE_CART_ITEM, RESELLER_DRAFT_CART_LIST, RESELLER_GET_CLINIC_FOR_DOCTOR, RESELLER_GET_DEFAULT_ADDRESS, RESELLER_GET_PATIENT_ADDRESS_BY_MOBILE, RESELLER_GET_PAYMENT_SUMMERY_FROM_TOKEN, RESELLER_GET_PRESCRIPTION_BY_APPOINTMENT, RESELLER_GET_PRESCRIPTION_BY_ORDERID, RESELLER_GET_PRESCRIPTION_DATA_BY_APPOINTMENT, RESELLER_MARK_PATIENT_ADDRESS_DEFAULT, RESELLER_ORDER_DETAILS_BY_ID, RESELLER_ORDER_LISTING, RESELLER_PAYMENT_SUMMERY, RESELLER_SEND_PAYMENT_LINK, RESELLER_UPDATE_DRAFT_CART, RESELLER_UPDATE_WHOLE_CART, RESELLER_VERFIY_PAYMENT_CODE, RESELLER_GET_CONSTANTS, GET_SERVICEBLE_PINCODE, RESELLER_GET_PATIENTS, RESELLER_ADD_PATIENT, RESELLER_GET_APPOINTMENTS, RESELLER_GET_APPOINTMENT, CREATE_RESELLER_APPOINTMENT, RESELLER_CHECK_ORDER_LIMIT, RESELLER_VERIFY_OTP_MOBILE, DASHBOARD_GET_CONSTANTS, GET_INDIVIDUAL_REQUEST_APPOINTMENT, PAYMENT_DETAILS, RESELLER_CART_COUNT, RESELLER_DELETE_CART_ITEMS, DELETE_CART_ITEMS, GET_RESELLER_ADD_PRESCRIPTION, ADD_TO_CART_V2, UPDATE_CART_V2, GET_SYSTEM_PARAMETER, GET_RESELLER_ADDRESS_URL, LIST_OF_STATES, GET_ADDRESS_URL, UPDATE_ADDRESS_URL, PRINTABLE_SUMMARY, ADD_ADDRESS_URL, RESELLER_DASHBOARD_DATA, RESELLER_DASHBOARD_ACTIVITY_DATA, APPOINTMENT_COUNTS, GET_PATIENT_NOTES, ADD_PATIENT_NOTES, DELETE_PATIENT_NOTE, ADD_PATIENT_NOTE, GET_LINKED_PATIENTS, GET_RESELLER_DASHBOARD_SLIDER_INFO, GET_EXISTING_APPOINTMENT, GET_USER_PAYOUT_LIST } from './url.service';

export const getDashboardInfo = (userId) => {
    return HttpClient.getWithAuth(GET_RESELLER_DASHBOARD_INFO(userId));
};

export const createBankDetails = (body) => {
    return HttpClient.postWithAuth(RESELLER_BANK_DETAILS_KYC(), body);
};

export const getResellerOrders = (userId, pageNumber, search, memberPatientId, appointmentId) => {
    return HttpClient.getWithAuth(RESELLER_ORDER_LISTING(userId, pageNumber, search, memberPatientId, appointmentId));
};

export const getResellerOrderDetails = (userId, orderId, isReseller) => {
    return HttpClient.getWithAuth(RESELLER_ORDER_DETAILS_BY_ID(userId, orderId, isReseller));
};

export const getResellerOrderDetailsWithToken = (userId, orderId, isReseller, token) => {
    return HttpClient.getWithToken(RESELLER_ORDER_DETAILS_BY_ID(userId, orderId, isReseller), token);
};

export const getPatientAddressesByMobile = (mobile, userId, doctorId) => {
    return HttpClient.getWithAuth(RESELLER_GET_PATIENT_ADDRESS_BY_MOBILE(mobile, userId, doctorId));
};

export const createPatientAddress = (body) => {
    return HttpClient.postWithAuth(RESELLER_ADD_PATIENT_ADDRESS(), body);
};

export const getResellerPatientCart = (userId, memberPatientId, doctorId) => {
    return HttpClient.getWithAuth(GET_RESELLER_PATIENT_CART(userId, memberPatientId, doctorId));
};


// export const addToCart = (body) => {
//     return HttpClient.postWithAuth(RESELLER_ADD_TO_CART(), body);
// };

export const updateDraftCart = (body) => {
    return HttpClient.putWithAuth(UPDATE_CART_V2(), body);
};

export const deleteDraftCart = (id) => {
    return HttpClient.deleteWithAuth(RESELLER_DELETE_CART_ITEM(id));
};

export const deleteResellerDraftCart = (id, body) => {
    return HttpClient.postWithAuth(DELETE_CART_ITEMS(id), body);
};

export const getPaymentSummary = (apiBody) => {
    return HttpClient.postWithAuth(PAYMENT_DETAILS(), apiBody);
};

export const getPaymentSummaryWithToken = (apiBody, token) => {
    return HttpClient.postWithToken(PAYMENT_DETAILS(), apiBody, token);
};

export const addPrescription = (appointmentId, body) => {
    return HttpClient.putWithAuth(RESELLER_ADD_PRESCRIPTION(appointmentId), body);
};

export const createResellerOrder = (body) => {
    return HttpClient.postWithAuth(RESELLER_CREATE_ORDER(), body);
};

export const createResellerOrderWithToken = (body, token) => {
    return HttpClient.postWithToken(RESELLER_CREATE_ORDER(), body, token);
};

export const createShareableLink = (body) => {
    return HttpClient.postWithAuth(RESELLER_CREATE_SHAREABLE_LINK(), body);
};

export const getResellerPaymentSummeryForPatientByToken = (token) => {
    return HttpClient.getWithToken(RESELLER_GET_PAYMENT_SUMMERY_FROM_TOKEN(), token);
};

export const verifyPaymentCode = (code) => {
    return HttpClient.getWithOutAuth(RESELLER_VERFIY_PAYMENT_CODE(code));
};

export const sendPatientPaymentlink = (body) => {
    return HttpClient.postWithAuth(RESELLER_SEND_PAYMENT_LINK(), body);
};

export const getDraftCartList = (body) => {
    return HttpClient.postWithAuth(RESELLER_DRAFT_CART_LIST(), body);
};

export const getClinicForDoctor = (userId) => {
    return HttpClient.getWithAuth(RESELLER_GET_CLINIC_FOR_DOCTOR(userId));
};

export const markAddressDefault = (body) => {
    return HttpClient.putWithAuth(RESELLER_MARK_PATIENT_ADDRESS_DEFAULT(), body);
};

export const getDefaultAddress = (patientUserId, memberPatientId) => {
    return HttpClient.getWithAuth(RESELLER_GET_DEFAULT_ADDRESS(patientUserId, memberPatientId));
};

export const updateWholeCart = (body) => {
    return HttpClient.putWithAuth(RESELLER_UPDATE_WHOLE_CART(), body);
};

export const getPrescriptionByOrderId = (orderId) => {
    return HttpClient.getWithOutAuth(RESELLER_GET_PRESCRIPTION_BY_ORDERID(orderId));
};

export const getPrescriptionByAppointmentId = (appointmentId, token, type='') => {
    return HttpClient.getWithToken(RESELLER_GET_PRESCRIPTION_BY_APPOINTMENT(appointmentId, type), token);
};

export const getPrescriptionDataByAppointmentId = ({ appointmentId, type="" }, token) => {
    return HttpClient.getWithToken(RESELLER_GET_PRESCRIPTION_DATA_BY_APPOINTMENT(appointmentId, type), token);
};

export const getResellerConstant = () => {
    return HttpClient.getWithOutAuth(RESELLER_GET_CONSTANTS());
};

export const getDashboardConstant = (type) => {
    return HttpClient.getWithOutAuth(DASHBOARD_GET_CONSTANTS(type));
};

export const GetServiceblePincode = (pincode, channel) => {
    return HttpClient.getWithOutAuth(GET_SERVICEBLE_PINCODE(pincode, channel));
}

export const getResellerPatients = (params, token) => {
    return HttpClient.getWithToken(RESELLER_GET_PATIENTS(params), token);
};

export const createPatient = (body) => {
    return HttpClient.postWithAuth(RESELLER_ADD_PATIENT(), body);
};

export const updatePatient = (body) => {
    return HttpClient.putWithAuth(RESELLER_ADD_PATIENT(), body);
};

export const getResellerAppointments = (params, token) => {
    return HttpClient.getWithToken(RESELLER_GET_APPOINTMENTS(params), token);
};

export const getResellerAddressesShared = (userID, patientId, token) => {
    return HttpClient.getWithToken(GET_RESELLER_ADDRESS_URL(userID, patientId), token)
}

export const getAddressesShared = (userID, token) => {
    return HttpClient.getWithToken(GET_ADDRESS_URL(userID), token)
}
export const updateAddressShared = (data, address_id, token) => {
    return HttpClient.putWithToken(UPDATE_ADDRESS_URL(address_id), data, token);
}

export const createAddressShared = (data,token) => {
    return HttpClient.postWithToken(ADD_ADDRESS_URL(), data, token);
}

export const getStatesListShared = (country_id, token) => {
    return HttpClient.getWithToken(LIST_OF_STATES(country_id), token);
};

export const getResellerAppointment = (appointmentId, token) => {
    return HttpClient.getWithToken(RESELLER_GET_APPOINTMENT({ appointmentId }), token);
};

export const createResellerAppointment = (body, token) => {
    return HttpClient.postWithToken(CREATE_RESELLER_APPOINTMENT(), body, token);
};

export const updateResellerAppointment = (appointmentId, body) => {
    return HttpClient.putWithAuth(RESELLER_GET_APPOINTMENT({ appointmentId }), body);
};

export const checkOrderLimit = ({ mobile, doctorId, memberPatientId }) => {
    return HttpClient.getWithAuth(RESELLER_CHECK_ORDER_LIMIT({ mobile, doctorId, memberPatientId }));
}

export const resellerVerifyOtpMobile = (mobile) => {
    return HttpClient.getWithAuth(RESELLER_VERIFY_OTP_MOBILE(mobile));
};

export const getResellerCount = (userId, memberPatientId, doctorId = '') => {
    return HttpClient.getWithAuth(RESELLER_CART_COUNT(userId, memberPatientId, doctorId));
};

export const getResellerPrescription = (appointmentId) => {
    return HttpClient.getWithAuth(GET_RESELLER_ADD_PRESCRIPTION(appointmentId));
};

export const addToCart = (userId, data) => {
    return HttpClient.postWithAuth(ADD_TO_CART_V2(userId), data);
};
export const getCustomerSupportNumber = (paramName, type) => {
    return HttpClient.getWithAuth(GET_SYSTEM_PARAMETER(paramName, type));
};

export const getPrintableSummary = (apiBody) => {
    return HttpClient.postWithAuth(PRINTABLE_SUMMARY(), apiBody);
};

export const getResellerDashboardData = (params, token) => {
    return HttpClient.getWithToken(RESELLER_DASHBOARD_DATA(params), token);
};

export const getResellerDashboardActivityData = ({doctorId, searchType}, token) => {
    return HttpClient.getWithToken(RESELLER_DASHBOARD_ACTIVITY_DATA({doctorId, searchType}), token);
};

export const getVideosData = (paramName, type) => {
    return HttpClient.getWithAuth(GET_SYSTEM_PARAMETER(paramName, type));
};

export const getAppointmentCounts = (paramName, token) => {
    return HttpClient.getWithToken(APPOINTMENT_COUNTS(paramName), token);
};

export const getPatientNotes = (patientAssociationId, token) => {
    return HttpClient.getWithToken(GET_PATIENT_NOTES(patientAssociationId), token);
};

export const addPatientNote = ({patientAssociationId, params}) => {
    return HttpClient.postWithAuth(ADD_PATIENT_NOTE(patientAssociationId), params);
};

export const deletePatientNote = ({patientAssociationId, noteId}, body) => {
    return HttpClient.putWithAuth(DELETE_PATIENT_NOTE(patientAssociationId, noteId), body);
};

export const getResellerDashboardSliderInfo = ({ doctorId }) => {
    return HttpClient.getWithAuth(GET_RESELLER_DASHBOARD_SLIDER_INFO({ doctorId }));
};

export const getExistingAppoinment = ({patientAssociationId, doctorId}) => {
    return HttpClient.getWithAuth(GET_EXISTING_APPOINTMENT({patientAssociationId, doctorId}));
};

export const getCustomerSupportNumberShared = (paramName, type, token) => {
    return HttpClient.getWithToken(GET_SYSTEM_PARAMETER(paramName, type),token);
};

export const getUserPayoutList = (params, token) => {
    return HttpClient.getWithToken(GET_USER_PAYOUT_LIST(params), token)
};

import { CHANNEL } from 'constant/warehouse_pincode_mapping';
import { RegexAddressLandmark } from 'helper/regex';
import { yupValidator } from 'lib/yup-validator';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createAddress, getAddresses, getStatesList, getValidateGstAddress, removeDefaultAddress, updateAddress, updateDefaultBillingAddress } from 'services/address.service';
import { deleteMyAddress } from 'services/feed.service';
import { createAddressShared, getAddressesShared, updateAddressShared } from 'services/reseller.service';
import { GetPincodeData, GetServiceblePincode, updateUserPersonalContact } from 'services/user.service';
import * as yup from 'yup';
import { getAuth } from './../../services/identity.service';
import { handleAnalyticEventHandleAddressUpdate } from 'utils/ga-analytic';
const appId = process.env.NEXT_PUBLIC_APP_ID;
const addressContext = createContext()
import googleAnalytics from "../../assets/mo_engage/order_flow_ga.json"
import { SKIP_CART_API_CALL_FOR } from 'constant';


export function useAddressContext() {
    return useContext(addressContext)
}

export const AddressProvider = ({ children }) => {
    const [showAddress, setShowAddress] = useState(false)
    const [isOpenPincodeModal, setIsOpenPincodeModal] = useState(false)

    const [showBillingAddress, setShowBillingAddress] = useState(false)

    const [activeAddress, setActiveAddress] = useState(null)
    const [isChangeMessage, setIsChangeMessage] = useState(null)
    const [addressList, setAddressList] = useState([]);
    const [updateAddressData, setUpdateAddressData] = useState(null);
    const [isDefaultBillingAddress, setIsDefaultBillingAddress] = useState(true);
    const [isDefaultDeliveryAddress, setIsDefaultDeliveryAddress] = useState(true);
    const [deliveryAddressIndex, setDeliveryAddressIndex] = useState("");
    const [billingAddressIndex, setBillingAddressIndex] = useState("");
    const [stateList, setStateList] = useState([]);
    const [toEditDelete, setToEditDelete] = useState(null);
    const [isEditCheckDisabled, setIsEditCheckDisabled] = useState(false);
    const [isRemoveDisabled, setIsRemoveDisabled] = useState(false);
    const [modalForServicablePincode, setModalForServicablePincode] = useState(false);
    const [isShowAddressModal, setIsModalOpen] = useState(false)
    const [pincodeValue, setPincodeValue] = useState(null);
    const [stateCode, setStateCode] = useState(0);
    const [gstError, setGstError] = useState("");
    const [legalEntityName, setLegalEntityName] = useState("");
    const [legalEntityDate, setLegalEntityDate] = useState(null);
    const [tradeName, setTradeName] = useState("");
    const [isForReseller, setIsForReseller] = useState(false)
    const [loading, setLoading] = useState(false)
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
    const [isOnSharedLink, setIsOnSharedLink] = useState(false)
    const [isOnSharedToken, setIsOnSharedToken] = useState(null)
    const [isSharedDocId, setIsSharedDocId] = useState(null)
    const [userPatientId, setUserPatientId] = useState("")
    const [isShowPincodeModal, setIsShowPincodeModal] = useState(false)
    const [isAddressModal, setIsAddressModal] = useState(true);
    const [isFromMarginSummary, setIsFromMarginSummary] = useState(false)
    const [isSelectePinCode, setIsSelectePinCode] = useState(null)
    const [addressAlertMsg, setAddressAlertMsg] = useState(null)

    const router = useRouter();
    const currRoute = SKIP_CART_API_CALL_FOR?.some(path => router?.pathname.startsWith(path)) || false;
    const analyticEntity = googleAnalytics.entity;

    const auth = getAuth();
    const userType = auth?.user?.userType


    
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        setError,
        watch,
        formState: { errors },
        control,
        reset,
    } = useForm({
        mode: 'onChange',
        resolver: yupValidator(getValidationSchema(isAddressModal , isForReseller, stateCode, setGstError, setLegalEntityName, userType))
    })


    function getValidationSchema(isAddressModal, isForReseller, stateCode, setGstError, setLegalEntityName, userType){
        if(isAddressModal)
        return yup.object().shape({
            fullName: yup.string()
                .test('len', 'Full name must be at least two characters', val => val.length >= 2)
                .matches(/^[aA-zZ\s]+$/, "Name can only have alphabets.")
                .test('len', "Enter your name in English", (val) => {
                    return !val.split("").filter(char => {
                        let charCode = char.charCodeAt();
                        return !((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode === 32));
                    }).length > 0;
                })
                .required("Full Name is Required"),
    
            ...(isAddressModal && {
                alternateMobileNo: yup.string()
                    .notOneOf([yup.ref("mobileNo"), null], (value) => {
                        if (!isForReseller) {
                            return "Please enter a different mobile number";
                        }
                    })
                    .matches(/^[6-9]\d{9}$/, "Mobile number is not valid")
                    .min(10, "Enter your 10 digit mobile number")
                    .max(10, "Max 10 digits are valid")
                    .required("Alternative mobile number is Required"),
    
                mobileNo: yup.string()
                    .notOneOf([yup.ref("alternateMobileNo"), null], (value) => {
                        if (isForReseller) {
                            return "Please enter a different mobile number";
                        }
                    })
                    .test("isNumericString", "Mobile can only have Numbers.", async (value) => /^[0-9]{1,10}$/.test(value))
                    .max(10, "Max 10 digits are valid")
                    .min(10, "Enter your 10 digit mobile number")
                    .required("Mobile number is Required"),
    
                houseNo: yup.string().trim()
                    .test('len', "The address should not contain &, \\ , %, #, ; and should be in English.", RegexAddressLandmark)
                    .min(10, "Enter at least 10 characters")
                    .max(250, "Cannot add more than 250 characters")
                    .required("Address is Required"),
            }),
    
            pincode: yup.string().required("Pincode is Required").test('len', "Enter your Valid 6 digit Pin Code.", async val => {
                if (!(/^[1-9][0-9]{5}$/.test(val))) {
                    return false;
                }
                const result = await GetPincodeData(val);
                return result.entity ? true : false;
            }).typeError("Should be a number"),
    
            city: yup.string()
                .test('len', "City must have at least 3 characters", val => val.length >= 3 && val.length <= 30)
                .test('len', "Enter city in English", (val) => {
                    let specialCharacters = [40, 41, 91, 92, 93, 95, 123, 125, 45, 32, 34, 38, 39, 44, 46, 47, 35, 36, 60, 62, 64, 58, 59];
                    return !val.split("").filter(char => {
                        let charCode = char.charCodeAt();
                        return !((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode >= 48 && charCode <= 57) || specialCharacters.includes(charCode));
                    }).length > 0;
                })
                .required("City is Required"),
    
            locality: yup.string()
                .test('len', "The landmark should not contain &, \\ , %, #, ; and should be in English.", RegexAddressLandmark)
                .min(2, "Enter at least 2 characters")
                .max(200, "Cannot add more than 200 characters")
                .required("Landmark is Required"),
    
            state: yup.string().required("State is Required"),
    
            ...(!isForReseller && isAddressModal && {
                gstNumber: yup.string()
                    .test('len', "Enter at least 15 characters", val => val.length < 2 || val.length >= 15)
                    .test('len', "State code of GSTIN and address don't match", async val => {
                        if (val.length < 15) return true;
                        if (val.length > 2) {
                            let gst_code = val.slice(0, 2);
                            return gst_code == stateCode && /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(val);
                        }
                    })
                    .test("match", gstError, async val => {
                        if ((val.length < 1 && (getValues('legalEntityName')?.length > 1))) {
                            setGstError('GST is required');
                            return false;
                        }
                        if (val.length === 15) return true;
                        if (userType == 6 && !val) {
                            setGstError('GST is Required');
                            return false;
                        }
                        return true;
                    }),
                legalEntityName: yup.string()
                    .when('gstNumber', {
                        is: val => val && val.length > 0,
                        then: yup.string()
                            .required('Legal Entity Name is required')
                            .test('match', "Legal entity name does not match with the name in GST certificate", async val => {
                                if (userType == 6 && !val) {
                                    setLegalEntityName('Legal Entity Name is required');
                                    return false;
                                }
                                if (watch('gstVerified') == 1) return true;
                                return val.length > 0;
                            }),
                    })
            })
        });
        else {
            return yup.object().shape({
                pincode: yup.string().required("Pincode is Required").test('len', "Enter your Valid 6 digit Pin Code.", async val => {
                    if (!(/^[1-9][0-9]{5}$/.test(val))) {
                        return false;
                    }
                    const result = await GetPincodeData(val);
                    return result.entity ? true : false;
                }).typeError("Should be a number"),
        
                city: yup.string()
                    .test('len', "City must have at least 3 characters", val => val.length >= 3 && val.length <= 30)
                    .test('len', "Enter city in English", (val) => {
                        let specialCharacters = [40, 41, 91, 92, 93, 95, 123, 125, 45, 32, 34, 38, 39, 44, 46, 47, 35, 36, 60, 62, 64, 58, 59];
                        return !val.split("").filter(char => {
                            let charCode = char.charCodeAt();
                            return !((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode >= 48 && charCode <= 57) || specialCharacters.includes(charCode));
                        }).length > 0;
                    })
                    .required("City is Required"),
                    state: yup.string().required("State is Required"),
            })
        }
    };

    // useEffect(()=>{
    //     listOfAddress()
    // },[pincodeValue])
    useEffect(() => {
        if (isForReseller) {
            const addressData = sessionStorage.getItem('patientAddress');
            const parsedData = JSON.parse(addressData)
            getAddresses(userPatientId || parsedData?.user_id || auth?.user?.id).then(data => {
                if (!data?.entity) return;

                const defaultAddress = data?.entity.find(address => address.is_default === 1);
                if (defaultAddress) {
                    setIsSelectePinCode(defaultAddress?.pin_code);
                }
            })
        }
    }, [isForReseller])


    useEffect(() => {
        if (addressList?.length == 1) {
            setIsRemoveDisabled(true)
        } else {
            setIsRemoveDisabled(false)
        }
    }, [addressList])

    const handleAddressChange = async (e, data) => {
        // const servicableResult = await GetServiceblePincode(data.pin_code, (auth?.user?.tbl_doctor_profile?.partner_access == 0 ? CHANNEL.B2B : CHANNEL.B2B2C))
        {/* handleAddressChange is only used at user level ( not in nirog partner ).So no need to check partner access bcz in general product will always come from B2B Channel */ }
        const servicableResult = await GetServiceblePincode(data.pin_code, CHANNEL.B2B)
        if (servicableResult.status == false) {
            setModalForServicablePincode(true)
            return
        }
        setIsChangeMessage(true)
        setActiveAddress(e?.target?.value)
        !showBillingAddress && setPincodeValue(`${data.city} ${data.pin_code}`)
        let statedata = stateList.find((list) => list.label.toLowerCase().trim() == data.State.name.toLowerCase().trim())
        const addressData = sessionStorage.getItem('patientAddress');
        const parsedData = JSON.parse(addressData)
        let reqbody = {
            "user_id": userPatientId|| parsedData?.user_id || getAuth()?.user?.id,
            "is_default": showBillingAddress ? data.is_default : 1,
            "is_billing": showBillingAddress ? 1 : data.is_billing,
            "updated_by": getAuth()?.user?.id,
        }
        updateDefaultBillingAddress({ ...reqbody, address_id: data.id }, data.id).then((res) => {
            if(!res?.status){
                setIsModalOpen(true)
                setButtonIsDisabled(false)
                setAddressAlertMsg({type: 'error', message: res?.message})
                return false;
            }
            const addressData = sessionStorage.getItem('patientAddress');
            const parsedData = JSON.parse(addressData)
            const stateData = stateList.find((item)=> item.value === statedata.value)
            sessionStorage.setItem('patientAddress', JSON.stringify({...parsedData, city: data.city, pin_code: data.pin_code, State: stateData}));
            if (window.location.pathname.includes("/medicines")) {
                window.location.reload(true)
            } else {
                listOfAddress()
            }
        });
        handleAnalyticEventHandleAddressUpdate({eventName: analyticEntity?.Address_Update?.event_name?.Select_Address, router})
        setPincodeValue(`${data.city} ${data.pincode}`)
    }


    const onCreateAddress = async (data, userId, patientId) => {
        setButtonIsDisabled(true);
        let responseCreateOrUpdate = {};
        let tradename = '';
        if (getValues('gstNumber')) {
            const response = await getValidateGstAddress(getValues('gstNumber'), getAuth()?.user?.id)
            if (response?.status) {
                setLegalEntityDate(response?.entity?.legalEntityName);
                tradename = response?.entity?.tradeName;
                setTradeName(response?.entity?.tradeName);
            } else {
                setError("gstNumber", { type: "custom", message: response?.message });
                setGstError(response?.message);
                setButtonIsDisabled(false);
                return false;
            }

            if (data.legalEntityName !== response?.entity?.legalEntityName) {
                setError("legalEntityName", { type: "custom", message: "Legal entity name does not match with the name in GST certificate" });
                setButtonIsDisabled(false);
                return false;
            }
        }

        const addressData = sessionStorage.getItem('patientAddress');
        const parsedData = JSON.parse(addressData)
        let reqbody = {
            "appID": appId,
            "user_id": userPatientId  || parsedData?.user_id || (patientId ? userId : getAuth()?.user?.id),
            "contact_person_name": data.fullName,
            "contact_person_phone": `${data.mobileNo}`,
            "address": `${data.houseNo}`,
            "landmark": data.locality,
            "city": data.city,
            "state": data.state,
            "pin_code": data.pincode,
            "alt_mobile": `${data.alternateMobileNo}`,
            "is_default": isDefaultDeliveryAddress ? 1 : 0,
            "is_billing": isDefaultBillingAddress ? 1 : 0,
            "created_by": isSharedDocId ? isSharedDocId : getAuth()?.user?.id,
            "updated_by": isSharedDocId ? isSharedDocId : getAuth()?.user?.id,
            ...(!patientId && !parsedData?.user_id && !userPatientId  ? {
                "legalEntityName": `${data?.legalEntityName}`,
                "gst": data.gstNumber,
                "gstVerified": `${data.legalEntityName ? 1 : 0}`,
                "tradeName": tradename,
            } : { "patient_id": patientId, }),
            
        }

        if (toEditDelete !== null) {
            let address_id = toEditDelete.id
            if (isOnSharedLink) {
                const updateResponse = await updateAddressShared({ ...reqbody }, address_id, isOnSharedToken);
                listOfAddress()
                responseCreateOrUpdate = updateResponse;
            } else {
                const updateResponse = await updateAddress({ ...reqbody }, address_id);
                if(!updateResponse?.status){
                    setIsModalOpen(true)
                    setButtonIsDisabled(false)
                    setAddressAlertMsg({type: 'error', message: updateResponse?.message})
                    return false;
                }
                listOfAddress()
                responseCreateOrUpdate = updateResponse;
            }
        } else {
            if (isOnSharedLink) {
                const createResponse = await createAddressShared(reqbody, isOnSharedToken);
                listOfAddress()
                responseCreateOrUpdate = createResponse;
            }else{
            const addressData = sessionStorage.getItem('patientAddress');
            const parsedData = JSON.parse(addressData)
            const createResponse = await createAddress(reqbody, getAuth()?.user?.id, patientId,);
            if(!createResponse?.status){
                setIsModalOpen(true)
                setButtonIsDisabled(false)
                setAddressAlertMsg({type: 'error', message: createResponse?.message})
                return false;
            }
            const stateData = stateList.find((item)=> item.value == data.state)
            sessionStorage.setItem('patientAddress', JSON.stringify({...parsedData,...createResponse?.entity, State: stateData}));
            listOfAddress()
            responseCreateOrUpdate = createResponse;
        }
        }
        if (isDefaultDeliveryAddress) {
            setPincodeValue(`${data.city} ${data.pincode}`)
        }
        reset();
        setToEditDelete(null);
        setIsModalOpen(false);
        setButtonIsDisabled(false);
        
        return responseCreateOrUpdate;
    }

    const onCreatePincode = async (data, userId, patientId) => {
        setButtonIsDisabled(true);
        let responseCreateOrUpdate = {};
        let tradename = '';
        if (getValues('gstNumber')) {
            const response = await getValidateGstAddress(getValues('gstNumber'), getAuth()?.user?.id)
            if (response?.status) {
                setLegalEntityDate(response?.entity?.legalEntityName);
                tradename = response?.entity?.tradeName;
                setTradeName(response?.entity?.tradeName);
            } else {
                setError("gstNumber", { type: "custom", message: response?.message });
                setGstError(response?.message);
                setButtonIsDisabled(false);
                return false;
            }

            if (data.legalEntityName !== response?.entity?.legalEntityName) {
                setError("legalEntityName", { type: "custom", message: "Legal entity name does not match with the name in GST certificate" });
                setButtonIsDisabled(false);
                return false;
            }
        }

        setIsModalOpen(false);

        let reqbody = {
            "DoctorProfile": {
            "appID": appId,
            "city": data.city,
            "state": data.state,
            "pin_code": data.pincode,
            },
            "userId": userPatientId || (patientId ? userId : getAuth()?.user?.id),
            "created_by": isSharedDocId ? isSharedDocId : getAuth()?.user?.id,
            "updatedBy": isSharedDocId ? isSharedDocId : getAuth()?.user?.id,   
        }

            const updateResponse = await removeDefaultAddress(userPatientId, {updatedBy: isSharedDocId ? isSharedDocId : getAuth()?.user?.id})
            const createResponse = await updateUserPersonalContact({ ...reqbody });
            listOfAddress()
            responseCreateOrUpdate = createResponse;
        if (isDefaultDeliveryAddress) {
            setPincodeValue(`${data.city} ${data.pincode}`)
        }

        const addressData = sessionStorage.getItem('patientAddress');
        const parsedData = JSON.parse(addressData)
        const stateData = stateList.find((item)=> item.value == data.state)
        sessionStorage.setItem('patientAddress', JSON.stringify({...parsedData, city: responseCreateOrUpdate.entity.city, pin_code: responseCreateOrUpdate.entity.pin_code, State: stateData}));
        setPincodeValue(`${data.city} ${data.pincode}`)
        reset();
        setToEditDelete(null);
        setIsModalOpen(false);
        setButtonIsDisabled(false);
        return responseCreateOrUpdate;
    }
    useEffect(() => {
        if (auth?.user?.id) {
            listOfAddress();
            listOfStates()
        }
        if (isOpenPincodeModal === false) {
            setShowBillingAddress(null);
        }
    }, [isOpenPincodeModal]);

    const listOfStates = () => {
        let country_id = 101
        if(!currRoute){
            getStatesList(country_id).then(data => {
                const listData = data?.entity?.map(item => {
                    return {
                        label: item.name,
                        value: item.id,
                        stateCode: item.stateCode
                    }
                })
                setStateList(listData)
            })
        }
    }

    const listOfAddress = () => {
        setLoading(true)
        if (isOnSharedLink && isOnSharedToken) {
            getAddressesShared(isOnSharedLink, isOnSharedToken).then(data => {
                if (!data?.entity) return;

                setAddressList(data.entity)
                setDeliveryAddressIndex(data.entity[0]?.id.toString());
                setBillingAddressIndex(data.entity[0]?.id.toString());
                if (data.entity?.length == 1) {
                    setIsRemoveDisabled(true)
                } else {
                    setIsRemoveDisabled(false)
                }
                setLoading(false)
            })
        } else {
            const addressData = sessionStorage.getItem('patientAddress');
            const parsedData = JSON.parse(addressData)
            if(!currRoute){
            getAddresses(userPatientId ||parsedData?.user_id || auth?.user?.id).then(data => {

                if (!data?.entity) return;
                setAddressList(data.entity)
                setDeliveryAddressIndex(data.entity[0]?.id.toString());
                setBillingAddressIndex(data.entity[0]?.id.toString());
                if (data.entity?.length == 1) {
                    setIsRemoveDisabled(true)
                } else {
                    setIsRemoveDisabled(false)
                }
                setLoading(false)
            })
        }
            
        }
    }

    const handleBillingAddress = (e) => {
        setIsDefaultBillingAddress(e.checked)
    }

    const handleDeliveryAddress = (e) => {
        setIsDefaultDeliveryAddress(e.checked)
    }
    const removeAddress = (addressId) => {
        deleteMyAddress(addressId, auth.user.id).then((res) => console.log(res))
        setAddressList(addressList.filter((data) => data.id !== addressId));
    }
    const changeAddress = () => {
        router.push('/myAddress');
    }

    const handleModalForServicablePincode = ({ event, confirm }) => {
        if (confirm) {
            setModalForServicablePincode(false);
        } else {
            setModalForServicablePincode(false);
        }
    };

    const handleModalStateChange = () => {
        setIsOpenPincodeModal(false)
        setShowAddress(false)
    }

    useEffect(() => {
        if(!isFromMarginSummary)
        setIsForReseller(false);
    }, [setIsForReseller, router.asPath])


    return (
        <addressContext.Provider value={{
            showAddress, setShowAddress, showBillingAddress, setShowBillingAddress, isOpenPincodeModal, setIsOpenPincodeModal, handleModalStateChange, register,
            handleSubmit, modalForServicablePincode, isRemoveDisabled, activeAddress, updateAddressData, setUpdateAddressData, isEditCheckDisabled, setIsEditCheckDisabled, deliveryAddressIndex, billingAddressIndex,
            setValue, pincodeValue, setPincodeValue, listOfStates, listOfAddress,
            getValues, setIsChangeMessage, setIsModalOpen, errors, isDefaultDeliveryAddress, isDefaultBillingAddress,
            clearErrors, control, stateList, setAddressList,
            setError, reset,
            watch, handleAddressChange, changeAddress, handleDeliveryAddress, onCreateAddress, isChangeMessage, addressList, isShowAddressModal, handleModalForServicablePincode, handleBillingAddress,
            stateCode, setStateCode, removeAddress, setIsDefaultDeliveryAddress, toEditDelete, setToEditDelete,
            setIsDefaultBillingAddress, setIsDefaultDeliveryAddress, tradeName, setTradeName, setIsForReseller, loading, userType, buttonIsDisabled, setButtonIsDisabled, setIsOnSharedLink, setIsOnSharedToken, setIsSharedDocId, userPatientId, setUserPatientId, onCreatePincode,
            isShowPincodeModal, setIsShowPincodeModal, setIsAddressModal, setIsFromMarginSummary, isSelectePinCode, addressAlertMsg, setAddressAlertMsg
        }}>
            {children}
        </addressContext.Provider>
    )
}

import axios from 'axios';
import { getAuth } from './identity.service'
const appId = process.env.NEXT_PUBLIC_APP_ID

export const postWithOutAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    const headers = {
      'content-type': 'application/json',
      'x-platform': 'web'
    };
    axios
      .post(url, entity, { headers })
      .then((response) => {
        if(response?.data?.message === "User deactivated!")
        document?.setIsUserActive(false);

        else if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};


export const postWithAuth = (url, entity) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const headers = {
      'content-type': 'application/json',
      'x-access-token': auth?.token,
      'x-platform': 'web'
    };
    axios.post(url, entity, { headers }).then((response) => {
      if (response && response.data) {
        document?.setIsUserActive(true);
        resolve(response.data)
      }
    }).catch(ex => {
      if (ex.response?.status == 401 && ex.response?.data?.message === "User deactivated!") {
        document?.setIsUserActive(false);
      } else {
        reject(ex);
      }
    })
  })
}

export const postWithToken = (url, entity, token = "") => {
  return new Promise((resolve, reject) => {
    const headers = {
      'content-type': 'application/json',
      'x-access-token': token,
      'x-platform': 'web'
    };
    axios.post(url, entity, { headers }).then((response) => {
      if (response && response.data) {
        resolve(response.data)
      }
    }).catch(ex => {
      reject(ex);
    })
  })
}


export const getWithOutAuth = (url) => {
  return new Promise((resolve, reject) => {
    const headers = {
      'content-type': 'application/json',
      'x-platform': 'web'
    };
    url = encodeURI(url)
    axios.get(url, { headers }).then((response) => {
if (response && response.data) {
        resolve(response.data)
      }
    }).catch(ex => {
      reject(ex);
    })
  })
}

export const getWithAuth = (url) => {
  const auth = getAuth();
  const headers = {
    'content-type': 'application/json',
    'x-access-token': auth?.token,
    'appID': appId,
    'x-platform': 'web'
  };
  return new Promise((resolve, reject) => {
    url = encodeURI(url)
    axios.get(url, { headers }).then((response) => {
      if(response?.data?.message === "User deactivated!")
      document?.setIsUserActive(false);

      else if (response && response.data) {
        document?.setIsUserActive(true);
        resolve(response.data)
      }
    }).catch(ex => {
      if (ex.response?.status == 401 && ex.response?.data?.message === "User deactivated!") {
        document?.setIsUserActive(false);
      } else {
        reject(ex);
      }
    })
  })
}

export const getWithToken = (url, token = "") => {
  const headers = {
    "content-type": "application/json",
    "x-access-token": token,
    "appID": appId,
    'x-platform': 'web'
  };
  return new Promise((resolve, reject) => {
    url = encodeURI(url)
    axios.get(url, { headers }).then((response) => {
      if (response && response.data) {
        resolve({ status: true, data: response.data });
      }
    }).catch((ex) => {
      resolve({ status: false, error: ex.message });
    });
  });
};

export const getWithTokenDataFormat = (url, token = "") => {
  const headers = {
    "content-type": "application/json",
    "x-access-token": token,
    "appID": appId,
    'x-platform': 'web'
  };
  return new Promise((resolve, reject) => {
    url = encodeURI(url)
    axios.get(url, { headers }).then((response) => {
      if (response && response.data) {
        resolve(response.data);
      }
    }).catch((ex) => {
      resolve({ status: false, error: ex.message });
    });
  });
};

export const deleteWithAuth = (url, entity) => {
  const auth = getAuth();
  const headers = {
    'content-type': 'application/json',
    'x-access-token': auth.token,
    'appId': appId,
    'x-platform': 'web'
  };
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers }, { data: entity }).then((response) => {
      if(response?.data?.message === "User deactivated!")
      document?.setIsUserActive(false);

      else if (response && response.data) {
        document?.setIsUserActive(true);
        resolve(response.data)
      }
    }).catch(ex => {
      if (ex.response?.status == 401 && ex.response?.data?.message === "User deactivated!") {
        document?.setIsUserActive(false);
      } else {
        reject(ex);
      }
    })
  })
}

export const putWithOutAuth = (url, entity) => {
  const auth = getAuth();
  const headers = {
    'content-type': 'application/json',
  };
  return new Promise((resolve, reject) => {
    axios.put(url, entity, { headers }).then((response) => {
      if (response && response.data) {
        document?.setIsUserActive(true);
        resolve(response.data)
      }
    }).catch(ex => {
      if (ex.response?.status == 401 && ex.response?.data?.message === "User deactivated!") {
        document?.setIsUserActive(false);
      }
      else {
        reject(ex);
      }
    })
  })
}

export const putWithAuth = (url, entity) => {
  const auth = getAuth();
  const headers = {
    'content-type': 'application/json',
    'x-access-token': auth.token
  };
  return new Promise((resolve, reject) => {
    axios.put(url, entity, { headers }).then((response) => {
      if(response?.data?.message === "User deactivated!")
      document?.setIsUserActive(false);

      else if (response && response.data) {
        document?.setIsUserActive(true);
        resolve(response.data)
      }
    }).catch(ex => {
      if (ex.response?.status == 401 && ex.response?.data?.message === "User deactivated!") {
        document?.setIsUserActive(false);
      }
      else {
        reject(ex);
      }
    })
  })
}

export const putWithToken = (url, entity, token = "") => {
  return new Promise((resolve, reject) => {
    const headers = {
      'content-type': 'application/json',
      'x-access-token': token,
      'x-platform': 'web'
    };
    axios.put(url, entity, { headers }).then((response) => {
      if (response && response.data) {
        resolve(response.data)
      }
    }).catch(ex => {
      reject(ex);
    })
  })
}

export const downloadImageWithOutAuth = (url) => {
  const headers = {
    'Content-Type': 'application/jpg',
  };
  return new Promise((resolve, reject) => {    
    axios.get(url, { headers }).then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.jpg`,
      );
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }).catch(ex => {
      reject(ex);
    })
  })
}

export const postDyteWithAuth = (url, entity) => {
  let base64Credentials = Buffer.from(`${process.env.NEXT_PUBLIC_DYTE_ORG_ID}:${process.env.NEXT_PUBLIC_DYTE_API_KEY}`).toString('base64');

  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const headers = {
      'content-type': 'application/json',
      'Authorization': `Basic ${base64Credentials}`,
    };
    axios.post(url, entity, { headers }).then((response) => {
      if(response?.data?.message === "User deactivated!")
      document?.setIsUserActive(false);

      else if (response && response.data) {
        resolve(response.data)
      }
    }).catch(ex => {
      reject(ex);
    })
  })
}
import { getAuth, getRedirectUrl, isAuthenticated, setRedirectUrl, setRedirectUrlForUserType } from '../../services/identity.service'
import { isProtected, isProtectedUserType } from "./Security";
import common_moengage from "../../assets/mo_engage/common_moengage_data.json";
import Loader from '../Loader/Loading';
import { useUserContext } from '../context/UserContext';

const isBrowser = () => typeof window !== "undefined";

const WithAuth = ({ router, children }) => {
  const attributes = common_moengage.entity;
  const statusesOfUser = {
      1: attributes.user_status_action.active_key,
      2: attributes.user_status_action.deleted_key,
  };


  const auth = getAuth();
  const paths = ["/login", "/sign-up", "/login-individual", "/login-practitioner", "/sign-up-individual", '/sign-up-practitioner', '/practitioner'];
  
  if (!auth || !Object.keys(auth?.user).length) {
    if (!paths.includes(router?.pathname)) setRedirectUrl(router?.asPath);
  }
  
  const isRedirectToSignUp = isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname);
  const { isRedirectionBashOnUserType, redirectionPath } = isBrowser() && isProtectedUserType(router.pathname, auth?.user?.userType, auth?.user?.tbl_doctor_profile?.is_verified);
  if (isRedirectToSignUp) {
    const redirectUrl = getRedirectUrl();
    setRedirectUrl(redirectUrl);
    router.replace('/login-individual');
  } else if (isBrowser() && isAuthenticated(auth)) {
    if (isRedirectionBashOnUserType) {
      router.replace(redirectionPath);
      setRedirectUrlForUserType(router.asPath);
    }
    if (Moengage && auth?.user?.id) Moengage.add_unique_user_id(auth.user.id);
    if (auth.user.email && Moengage) Moengage.add_email(auth.user.email);
    if (auth.user.mobileNumber && Moengage) Moengage.add_mobile(auth.user.mobileNumber);
    if (auth.user.fullName && Moengage) Moengage.add_user_name(auth.user.fullName);
    if (Moengage && auth?.user?.id) {
      Moengage.add_user_attribute(attributes.user_attributes_click.user_status_key, statusesOfUser[auth?.user?.status]);
      Moengage.add_user_attribute(attributes.user_attributes_click.ext_user_id_key, auth?.user?.extUserId);
    }
    if (router.pathname === '/sign-up') {
      router.replace('/medicines');
    }
    if(['/reseller', '/my-patients/select-address', '/my-appointments'].some(str=> router?.state?.pathname?.indexOf(str) != -1) === false){
      sessionStorage.removeItem('patientAddress')
    }
  }
  return isRedirectToSignUp || isRedirectionBashOnUserType ? <Loader type='layer' /> : children;
};
export default WithAuth;


import googleAnalytics from "../../assets/mo_engage/order_flow_ga.json"
import { captureEvent } from 'services/analytics.service';



export const handleAnalyticEventAddToCart = ({ medicineName = '', brandName = '', router, isProductDetailsPage = false }) => {
    const analyticEntity = googleAnalytics.entity;
    let attributes = {
        ...analyticEntity?.Add_To_Cart?.attributes,
        brandName,
        medicineName
    };
    const path = router?.asPath
    const eventName = analyticEntity?.Add_To_Cart?.event_name;
    const eventLabel = analyticEntity?.Add_To_Cart?.event_name;
    const eventCategory = isProductDetailsPage ? analyticEntity?.Add_To_Cart?.category?.pdp_page : path == '/' ? analyticEntity?.Add_To_Cart?.category?.home_page : path + analyticEntity?.Add_To_Cart?.category?.plp_page;
    captureEvent(eventCategory, eventName, eventLabel, attributes);
}

export const handleAnalyticEventSelectPackaging = ({ medicineName = '', brandName = '', PackagingType, router, isProductDetailsPage = false }) => {
    const analyticEntity = googleAnalytics.entity;
    const path = router?.asPath
    const attributes = {
        ...analyticEntity?.Select_Packaging?.attributes,
        brandName,
        medicineName,
        PackagingType
    };
    const eventName = analyticEntity?.Select_Packaging?.event_name;
    const eventLabel = analyticEntity?.Select_Packaging?.event_name;
    const eventCategory = path == '/' ? analyticEntity?.Select_Packaging?.category?.home_page : isProductDetailsPage ? analyticEntity?.Select_Packaging?.category?.pdp_page : path + analyticEntity?.Select_Packaging?.category?.plp_page;
    captureEvent(eventCategory, eventName, eventLabel, attributes);
}

export const handleAnalyticEventNotifyMe = ({ medicineName = '', brandName = '', router, isProductDetailsPage = false }) => {
    const analyticEntity = googleAnalytics.entity;
    const path = router?.asPath
    const attributes = {
        ...analyticEntity?.Notify_Me?.attributes,
        brandName,
        medicineName,
    };
    const eventName = analyticEntity?.Notify_Me?.event_name;
    const eventLabel = analyticEntity?.Notify_Me?.event_name;
    const eventCategory = path == '/' ? analyticEntity?.Notify_Me?.category?.home_page : isProductDetailsPage ? analyticEntity?.Notify_Me?.category?.pdp_page : path + analyticEntity?.Notify_Me?.category?.plp_page;
    captureEvent(eventCategory, eventName, eventLabel, attributes);
}

export const handleAnalyticEventProceedToCart = ({ medicineName = '', brandName = '', router, isProductDetailsPage = false }) => {
    const analyticEntity = googleAnalytics.entity;
    const path = router?.asPath
    const attributes = {
        ...analyticEntity?.Proceed_To_Cart?.attributes,
        brandName,
        medicineName,
    };
    const eventName = analyticEntity?.Proceed_To_Cart?.event_name;
    const eventLabel = analyticEntity?.Proceed_To_Cart?.event_name;
    const eventCategory = path == '/' ? analyticEntity?.Proceed_To_Cart?.category?.home_page : isProductDetailsPage ? analyticEntity?.Proceed_To_Cart?.category?.pdp_page : path + analyticEntity?.Proceed_To_Cart?.category?.plp_page;
    captureEvent(eventCategory, eventName, eventLabel, attributes);
}

export const handleAnalyticEventViewCart = ({ medicineName = '', brandName = '', router, isProductDetailsPage = false }) => {
    const analyticEntity = googleAnalytics.entity;
    const path = router?.asPath
    const attributes = {
        ...analyticEntity?.View_Cart?.attributes,
        brandName,
        medicineName,
    };
    const eventName = analyticEntity?.View_Cart?.event_name;
    const eventLabel = analyticEntity?.View_Cart?.event_name;
    const eventCategory = path == '/' ? analyticEntity?.View_Cart?.category?.home_page : isProductDetailsPage ? analyticEntity?.View_Cart?.category?.pdp_page : path + analyticEntity?.View_Cart?.category?.plp_page;
    captureEvent(eventCategory, eventName, eventLabel, attributes);
}

export const handleAnalyticEventSaveMedicine = ({ medicineName = '', brandName = '', router, isProductDetailsPage = false }) => {
    const analyticEntity = googleAnalytics.entity;
    const path = router?.asPath
    const attributes = {
        ...analyticEntity?.Save_Medicine?.attributes,
        brandName,
        medicineName,
    };
    const eventName = analyticEntity?.Save_Medicine?.event_name;
    const eventLabel = analyticEntity?.Save_Medicine?.event_name;
    const eventCategory = path == '/' ? analyticEntity?.Save_Medicine?.category?.home_page : isProductDetailsPage ? analyticEntity?.Save_Medicine?.category?.pdp_page : path + analyticEntity?.Save_Medicine?.category?.plp_page;
    captureEvent(eventCategory, eventName, eventLabel, attributes);
}

export const handleAnalyticEventCouponApply = ({ couponCode = '', action = '', eventName, router }) => {
    const path = router?.asPath
    const analyticEntity = googleAnalytics.entity;
    const attributes = {
        ...analyticEntity?.Offer_Apply?.attributes,
        couponCode,
        action
    };

    const eventCategory = path + analyticEntity?.Offer_Apply?.category?.payment_page
    captureEvent(eventCategory, eventName, eventName, attributes);
}

export const handleAnalyticEventOfferApply = ({ medicineName = '', brandName = '', eventName, router }) => {
    const analyticEntity = googleAnalytics.entity;
    const path = router?.asPath
    const attributes = {
        ...analyticEntity?.Offer_Apply?.attributes,
        medicineName,
        brandName
    };

    const eventCategory = path + analyticEntity?.Offer_Apply?.category?.payment_page
    captureEvent(eventCategory, eventName, eventName, attributes);
}

export const handleAnalyticEventViewDetailBill = ({ router }) => {
    const path = router?.asPath
    const analyticEntity = googleAnalytics.entity;
    const eventCategory = path + analyticEntity?.View_Detail_Bill?.category?.payment_page
    const eventName = analyticEntity?.View_Detail_Bill?.event_name?.View_Detail_Bill
    captureEvent(eventCategory, eventName, eventName);
}

export const handleAnalyticEventNirogMoney = ({ eventName = '', nirogMoney = '' }) => {
    const analyticEntity = googleAnalytics.entity;
    const attributes = {
        ...analyticEntity?.Nirog_Money_Apply?.attributes,
        nirogMoney
    }
    const eventCategory = analyticEntity?.View_Detail_Bill?.category?.payment_page
    captureEvent(eventCategory, eventName, eventName, attributes);
}

export const handleAnalyticEventMOP = ({ action = '', router }) => {
    const analyticEntity = googleAnalytics.entity;
    const path = router?.asPath
    const attributes = {
        action
    }
    const eventName = analyticEntity?.Mode_Of_Payment?.event_name?.Mode_Of_Payment
    const eventCategory = path
    captureEvent(eventCategory, eventName, eventName, attributes);
}

export const handleAnalyticEventOTP = ({ action = '', label = '' }) => {
    const analyticEntity = googleAnalytics.entity;
    const attributes = {
        action
    }
    const eventName = analyticEntity?.OTP?.event_name?.OTP
    const eventCategory = analyticEntity?.OTP?.category?.Order_Confirmation
    captureEvent(eventCategory, eventName, label, attributes);
}

export const handleAnalyticEventOrderConfirmation = ({ action = '', label = '' }) => {
    const analyticEntity = googleAnalytics.entity;
    const attributes = {
        action
    }
    const eventName = analyticEntity?.Order_Confirmation?.event_name?.Order_Confirmation
    captureEvent(action, eventName, label, attributes);
}

export const handleAnalyticEventCancelPayment = ({ category = '', label = '' }) => {
    const analyticEntity = googleAnalytics.entity;
    const attributes = {
        action: category
    }
    const eventName = analyticEntity?.Cancel_Payment?.event_name?.Cancel_Payment
    captureEvent(category, eventName, label, attributes);
}

export const handleAnalyticEventHandleProgressBar = ({ router }) => {
    const analyticEntity = googleAnalytics.entity;
    const path = router?.asPath
    const attributes = {
        path
    }
    const eventName = analyticEntity?.Progress_Bar?.event_name?.Progress_Bar
    captureEvent(path, eventName, eventName, attributes);
}

export const handleAnalyticEventHandleCartItemEdit = ({ eventName = '', medicineName = '', brandName = '', router }) => {
    const attributes = {
        medicineName, brandName
    }
    const path = router?.asPath
    captureEvent(path, eventName, eventName, attributes);
}

export const handleAnalyticEventHandleProceedOrderFlow = ({ router }) => {
    const path = router?.asPath
    const analyticEntity = googleAnalytics.entity;
    const attributes = analyticEntity?.Proceed_Order_Flow?.attributes
    const eventName = analyticEntity?.Proceed_Order_Flow?.event_name?.Proceed_Order_Flow
    captureEvent(path, eventName, eventName, attributes);
}

export const handleAnalyticEventHandleAddressUpdate = ({ router, eventName = '' }) => {
    const path = router?.asPath
    captureEvent(path, eventName, eventName);
}

export const handleAnalyticEventHandleNavBarNavigation = ({ router }) => {
    const path = router?.asPath
    const analyticEntity = googleAnalytics.entity;
    const eventName = analyticEntity?.Navbar_Navigation?.event_name?.Navbar_Navigation
    captureEvent(path, eventName, eventName);
}

export const handleAnalyticEventHandleHeaderNavigation = ({ eventName, router }) => {
    const path = router?.asPath
    captureEvent(path, eventName, eventName);
}

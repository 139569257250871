import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAuth = () => {
  const auth = cookies.get('AUTH');

  return auth
}

export const setAuth = (authObject) => {
  cookies.set('AUTH', JSON.stringify(authObject), { path: '/', maxAge: 604800 });
  return authObject;
}

export const setRedirectUrl = (url) => {
  cookies.set('redirectUrlAfterLogin', url, { path: '/' });
}

export const setRedirectUrlForUserType = (url) => {
  cookies.set('redirectUrlAfterLoginUserType', url, { path: '/' });
}

export const getRedirectUrl = () => {
  return cookies.get('redirectUrlAfterLogin');
}

export const getRedirectUrlForUserType = () => {
  return cookies.get('redirectUrlAfterLoginUserType');
}

export const removeRedirectUrlForUserType = () => {
  cookies.remove('redirectUrlAfterLoginUserType', { path: '/' });
}

export const removeAuth = () => {
  cookies.remove('AUTH', { path: '/' });
  return;
}

export const isInRole = (role, user) => {
  return user.roles && user.roles.includes(role);
}

export const isAuthenticated = (user) => {
  return user != null && user.token;
}

export const isUnauthorizedRequest = (auth) => {
  return !auth || !isAuthenticated(JSON.parse(auth));
};

export const getSourceOfUser = () => {
  return cookies.get('sourceOfUser');
}

export const setSourceOfUser = (section) => {
  cookies.set('sourceOfUser', section, { path: '/' });
}

export const removeSourceOfUser = (section) => {
  cookies.remove('sourceOfUser', { path: '/' });
}
import { getAuth } from "services/identity.service.js";
import ReactGA from "react-ga4";

const useMoengage = process.env.NEXT_PUBLIC_USE_MOENGAGE == 'true';
const useGoogleAnalytics = process.env.NEXT_PUBLIC_USE_GOOGLE_ANALYTICS == 'true';
//adding a toggler for enabling Moengage and GA

export const trackEvent = (eventName, details) => {
    const auth = getAuth();
    if (useMoengage == true) {
        try {
            if (auth?.user?.id) window.Moengage.track_event(eventName, details);
        }
        catch (err) {
            console.log(err);
        }
    }
     if (useGoogleAnalytics == true) {
        try {
            ReactGA.event(eventName, details);
        }
        catch {
            console.log(err);

        }
    }
}


export const destroySession = () => {
    try {
        window.Moengage.destroy_session()
    }
    catch (err) {
        console.log(err);
    }
}
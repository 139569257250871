import React from 'react'

export default function Loader({ type = 'layer', className = '', color }) {
  return (
    type === 'layer' ? (
      <>
        <div className={className}>
          <div className='fullLayer fullLayer--active'></div>
          <div className="loader z-90 fixed">
            <div className="loader__figure"></div>
          </div>
        </div>
      </>
    ) : type === 'spinner' ? (
      <div className='spinner'>
        <svg className={`${className} spinner--small ${color || ''}`} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="40" fill='none' stroke='currentColor' />
        </svg>
      </div>
    ) : type === 'inline' ? (
      <div className={` ${className} inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-primary1-400 align-[-0.125em] motion-safe:animate-spin`}>
        <span className="sr-only">Loading...</span>
      </div>
    ) : null
  );
}
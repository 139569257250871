import { createContext, useContext, useState } from 'react';
import { getAuth, setAuth } from './../../services/identity.service';
import * as moengage from "services/moengage.service";
import mo_engage_onboarding from "../../assets/mo_engage/onboarding";
import { useRouter } from 'next/router';

import Loading from 'components/Loader/Loading';
import dynamic from 'next/dynamic';
const ModalForNirogMoney = dynamic(() => import('./../../molecules/Modals/ModalForNirogMoney'), { ssr: false, loading: () => <Loading type='layer' /> });

const modalContext = createContext()

export function useModalContext() {
    return useContext(modalContext)
}

export const ModalProvider = ({ children }) => {
    const auth = getAuth();
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const [nirogMoney, setNirogMoney] = useState('');
    const [type, setType] = useState('nirogMoney');
    const entity = mo_engage_onboarding.entity;
    const router = useRouter()


    const handlerModalClose = (e) => {
        setShowModal(false);
        if (e?.target?.innerHTML === 'Skip') {
            moengage.trackEvent(entity.onboarding_homepage_success_signup_popup_skip.event_name, {})
        } else if (e?.target?.nodeName === "svg") {
            moengage.trackEvent(entity.onboarding_homepage_success_signup_popup_close.event_name, {})
        }
    }

    return (
        <modalContext.Provider value={{ setShowModal, setMessage, setNirogMoney, setType }}>
            {showModal && <ModalForNirogMoney type={type} showModal={showModal} handlerModalClose={handlerModalClose} message={message} nirogMoney={nirogMoney} />}
            {children}
        </modalContext.Provider>
    )
}

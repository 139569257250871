import ReactGA from "react-ga4";

export const captureEvent = (
    category,
    event_name,
    label,
    data
) => {
    let event_params = {
        category,
        label,
        ...data
    };
    ReactGA.event(event_name, event_params);
};

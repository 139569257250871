import { useRouter } from 'next/router';
import { createContext, useContext, useState } from 'react';
import { getAuth, setRedirectUrl } from 'services/identity.service';
import { sendOtpForLogin } from 'services/user.service';

import Loading from 'components/Loader/Loading';
import dynamic from 'next/dynamic';
import { USER_TYPES } from 'constant';
const ModalParticipateOlympiad = dynamic(() => import('molecules/Modals/ModalParticipateOlympiad'), { ssr: false, loading: () => <Loading type='layer' /> });
const ModalInformational = dynamic(() => import('molecules/Modals/ModalInformational'), { ssr: false, loading: () => <Loading type='layer' /> });
const ModalForDeactivatedUser = dynamic(() => import('molecules/Modals/ModalForDeactivatedUserLogin'), { ssr: false, loading: () => <Loading type='layer' /> });

const participateOlympiadContext = createContext();

export function useParticipateOlympiadContext() {
    return useContext(participateOlympiadContext)
}

export const ParticipateOlympiadProvider = ({ children }) => {
    const router = useRouter();

    const [isParticipateOpen, setIsParticipateOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isParticipate, setIsParticipate] = useState(false);
    const [isUserDeactivated, setIsUserDeactivated] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [olympiadSuccessMessage, setOlympiadSuccessMessage] = useState('');
    const [olympiadName, setOlympiadName] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const [isOlympiadLive, setIsOlympiadLive] = useState(false);
    const auth = getAuth();

    const [isExistingUser, setIsExistingUser] = useState(false);


    const handleUserData = async (userData) => {
        setUserData(userData)
        setRedirectUrl(router.pathname);
        router.push('/sign-up?type=practitioner')
        if (!isExistingUser) {
            const response = await sendOtpForLogin({
                mobileNumber: userData?.mobileNo,
                country_code: "91"

            })
            if (response?.data?.message.code === 111 && response?.data?.message.error === 'Your account has been deactivated') {
                setIsUserDeactivated(true);
                return
            }
            if (response?.data?.message === "Successfully sent Otp!") {
                setIsExistingUser(true);
            } else {
                setIsExistingUser(false);
            }
        }
    }


    return (
        <participateOlympiadContext.Provider value={{ setIsSuccessModalOpen, setIsParticipateOpen, setUserData, handleUserData, userData, setIsParticipate, olympiadSuccessMessage, isParticipate, isExistingUser, setIsExistingUser, setOlympiadSuccessMessage, setIsRegistered, isRegistered, setOlympiadName, setIsOlympiadLive }}>
            {isParticipateOpen && <ModalParticipateOlympiad
                isModalOpen={isParticipateOpen}
                heading={`Participate in ${olympiadName}`}
                paragraph={`Enter your name and mobile number to participate & receive notifications about ${olympiadName}.`}
                handlerModalClose={() => setIsParticipateOpen(false)}
            />}
            {(userData && ![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType)) && <ModalInformational
                isModalOpen={isSuccessModalOpen}
                handlerModalClose={() => setIsSuccessModalOpen(false)}
                isShowCloseBtn={true}
                heading="Registration Successful"
                paragraph={olympiadSuccessMessage}
                primaryButtonText="Okay"
                image={'/images/olympiad_mobile.svg'}
                primaryButtonOnChange={() => { isOlympiadLive ? router.push(`/olympiad/top-performers`) : ''; setIsSuccessModalOpen(false); setUserData(null) }}
            />}

            {isUserDeactivated && <ModalForDeactivatedUser isUserDeactivated={isUserDeactivated} setIsUserDeactivated={setIsUserDeactivated} />}
            {children}
        </participateOlympiadContext.Provider>
    )
}
